<template>
    <div class="client-settings">
        <div class="client-settings__title-container">
            <Title>
                Settings {{ client.nonEditable ? '(nonEditable)' : null }}
            </Title>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Enabled
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.enabled"
                           :label="item.label"
                           :value="item.value"
                           name="enabled"
                           :checked="item.value === fields.enabled"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Allow offline access
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.allowOfflineAccess"
                           :label="item.label"
                           :value="item.value"
                           name="allowOfflineAccess"
                           :checked="item.value === fields.allowOfflineAccess"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Require client secret
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.requireClientSecret"
                           :label="item.label"
                           :value="item.value"
                           name="requireClientSecret"
                           :checked="item.value === fields.requireClientSecret"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Require consent
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.requireConsent"
                           :label="item.label"
                           :value="item.value"
                           name="requireConsent"
                           :checked="item.value === fields.requireConsent"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Require Pkce
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.requirePkce"
                           :label="item.label"
                           :value="item.value"
                           name="requirePkce"
                           :checked="item.value === fields.requirePkce"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Allow plain text Pkce
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.allowPlainTextPkce"
                           :label="item.label"
                           :value="item.value"
                           name="allowPlainTextPkce"
                           :checked="item.value === fields.allowPlainTextPkce"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Update access token claims on refresh
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.updateAccessTokenClaimsOnRefresh"
                           :label="item.label"
                           :value="item.value"
                           name="updateAccessTokenClaimsOnRefresh"
                           :checked="item.value === fields.updateAccessTokenClaimsOnRefresh"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Always send client claims
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.alwaysSendClientClaims"
                           :label="item.label"
                           :value="item.value"
                           name="alwaysSendClientClaims"
                           :checked="item.value === fields.alwaysSendClientClaims"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Back channel logout session required
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.backChannelLogoutSessionRequired"
                           :label="item.label"
                           :value="item.value"
                           name="backChannelLogoutSessionRequired"
                           :checked="item.value === fields.backChannelLogoutSessionRequired"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Front channel logout session required
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.frontChannelLogoutSessionRequired"
                           :label="item.label"
                           :value="item.value"
                           name="frontChannelLogoutSessionRequired"
                           :checked="item.value === fields.frontChannelLogoutSessionRequired"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Require DPoP
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.requireDPoP"
                           :label="item.label"
                           :value="item.value"
                           name="requireDPoP"
                           :checked="item.value === fields.requireDPoP"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Require pushed authorization
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.requirePushedAuthorization"
                           :label="item.label"
                           :value="item.value"
                           name="requirePushedAuthorization"
                           :checked="item.value === fields.requirePushedAuthorization"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                MFA Enabled
            </div>
            <div class="client-settings__radio-container">
                <div v-for="item in radioItems"
                     :key="item.value"
                     class="client-settings__radio-holder">
                    <Radio v-model="fields.mfaEnabled"
                           :label="item.label"
                           :value="item.value"
                           name="mfaEnabled"
                           :checked="item.value === fields.mfaEnabled"
                           :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <div class="client-settings__label">
                Grants
            </div>
            <div class="client-settings__checkbox-container">
                <div v-for="grant in grants"
                     :key="grant"
                     class="client-settings__checkbox-holder">
                    <Checkbox v-model="fields.grants"
                              :label="grant"
                              :value="grant"
                              name="grants"
                              checked
                              :disabled="client.nonEditable" />
                </div>
            </div>
        </div>

        <div class="client-settings__input-container">
            <Input label="Description of the client"
                   name="description"
                   :disabled="client.nonEditable"
                   :value="fields.description"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Client Name"
                   name="clientName"
                   :disabled="client.nonEditable"
                   :value="fields.clientName"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Client claims prefix"
                   name="clientClaimsPrefix"
                   :disabled="client.nonEditable"
                   :value="fields.clientClaimsPrefix"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Back channel logout uri"
                   name="backChannelLogoutUri"
                   :disabled="client.nonEditable"
                   :value="fields.backChannelLogoutUri"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Front channel logout uri"
                   name="frontChannelLogoutUri"
                   :disabled="client.nonEditable"
                   :value="fields.frontChannelLogoutUri"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Identity token lifetime"
                   name="identityTokenLifetime"
                   type="number"
                   :disabled="client.nonEditable"
                   :value="fields.identityTokenLifetime"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Access token lifetime"
                   name="accessTokenLifetime"
                   type="number"
                   :disabled="client.nonEditable"
                   :value="fields.accessTokenLifetime"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Authorization code lifetime"
                   name="authorizationCodeLifetime"
                   type="number"
                   :disabled="client.nonEditable"
                   :value="fields.authorizationCodeLifetime"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Absolute refresh token lifetime"
                   name="absoluteRefreshTokenLifetime"
                   type="number"
                   :disabled="client.nonEditable"
                   :value="fields.absoluteRefreshTokenLifetime"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Sliding refresh token lifetime"
                   name="slidingRefreshTokenLifetime"
                   type="number"
                   :disabled="client.nonEditable"
                   :value="fields.slidingRefreshTokenLifetime"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Dropdown label="Refresh token usage"
                      name="refreshTokenUsage"
                      :items="tokenUsageItems"
                      :disabled="client.nonEditable"
                      :value="fields.refreshTokenUsage"
                      :onChange="onDropdownChange" />
        </div>

        <div class="client-settings__input-container">
            <Dropdown label="Refresh token expiration"
                      name="refreshTokenExpiration"
                      :items="tokenExpirationItems"
                      :disabled="client.nonEditable"
                      :value="fields.refreshTokenExpiration"
                      :onChange="onDropdownChange" />
        </div>

        <div class="client-settings__input-container">
            <Dropdown label="Classification"
                      name="classification"
                      :items="classificationItems"
                      :disabled="client.nonEditable"
                      :value="fields.classification ?? ''"
                      :onChange="onDropdownChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Device code lifetime"
                   name="deviceCodeLifetime"
                   type="number"
                   :disabled="client.nonEditable"
                   :value="fields.deviceCodeLifetime"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Dropdown label="DPoP validation mode"
                      name="dPoPValidationMode"
                      :items="dPoPValidationModeItems"
                      :disabled="client.nonEditable"
                      :value="fields.dPoPValidationMode"
                      :onChange="onDropdownChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="DPoP clock skew"
                   name="dPoPClockSkew"
                   :disabled="client.nonEditable"
                   :value="fields.dPoPClockSkew"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__input-container">
            <Input label="Pushed authorization lifetime"
                   name="pushedAuthorizationLifetime"
                   type="number"
                   :disabled="client.nonEditable"
                   :value="fields.pushedAuthorizationLifetime"
                   :onChange="onInputChange" />
        </div>

        <div class="client-settings__button-container">
            <Button :disabled="!clientChanged"
                    :loading="loading.button"
                    :onClick="onSaveClick">
                Save changes
            </Button>
        </div>
    </div>
</template>

<script src="./ClientSettings.js"></script>

<style
    src="./ClientSettings.scss"
    lang="scss"
    scoped
></style>

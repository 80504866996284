<template>
    <Modal
        :onClose="onClose"
    >
        <div class="modal-logo-upload">
            <div class="modal-logo-upload__title-container">
                <Title>
                    Upload client logo
                </Title>
            </div>

            <file-pond
                ref="pond"
                label-idle="Drop your image or <span class='filepond--label-action'>Browse</span>"
                :image-validate-size-max-width="256"
                :image-validate-size-max-height="256"
                :accepted-file-types="['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']"
                :max-files="1"
                :credits="null"
                :allowRevert="false"
                :server="serverOptions"
            ></file-pond>
        </div>
    </Modal>
</template>

<script src="./ModalLogoUpload.js"></script>

<style
    src="./ModalLogoUpload.scss"
    lang="scss"
    scoped
></style>

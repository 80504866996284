import axios from 'axios';
import Alert from '@/api/services/alert';
import router from '@/router';

axios.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 403) {
        router.push('/forbidden');
    }

    if (error?.response?.status === 401) {
        window.location.href = '/backend/bff/login?returnUrl=/';
    } else if (error.message) {
        Alert.error(error.message);
    } else {
        Alert.error(error);
    }

    return Promise.reject(error);
});

export default {
    init(env) {
        this.env = env;
    },

    makeRequest({
        method = 'GET',
        url,
        data,
        headers
    }) {
        const options = {
            method,
            url,
            data
        };

        options.headers = { 'X-CSRF': '1', ...headers };
        options.url = `/backend/api/v1${options.url}`;

        return axios(options);
    },

    getClients({
        page,
        pageSize
    }) {
        return this.makeRequest({
            url: `/clients?page=${page}&pageSize=${pageSize}`
        });
    },

    getClientTypes() {
        return this.makeRequest({
            url: '/clienttypes'
        });
    },

    createClient(client) {
        return this.makeRequest({
            method: 'POST',
            url: '/clients',
            data: client
        });
    },

    deleteClient(id) {
        return this.makeRequest({
            method: 'DELETE',
            url: `/clients/${id}`
        });
    },

    getClient(id) {
        return this.makeRequest({
            url: `/clients/${id}`
        });
    },

    updateClient(id, data) {
        return this.makeRequest({
            method: 'PUT',
            url: `/clients/${id}`,
            data
        });
    },

    getClientGrants(clientId) {
        return this.makeRequest({
            url: `/clients/${clientId}/grants`
        });
    },

    addClientGrant(clientId, grant) {
        const data = {
            grantType: grant
        };

        return this.makeRequest({
            method: 'POST',
            url: `/clients/${clientId}/grants`,
            data
        });
    },

    removeClientGrant(clientId, grantId) {
        return this.makeRequest({
            method: 'DELETE',
            url: `/clients/${clientId}/grants/${grantId}`
        });
    },

    getClientRedirects(id) {
        return this.makeRequest({
            url: `/clients/${id}/redirects`
        });
    },

    getClientScopes(id) {
        return this.makeRequest({
            url: `/clients/${id}/scopes`
        });
    },

    getClientClaims(id) {
        return this.makeRequest({
            url: `/clients/${id}/claims`
        });
    },

    getClientPostLogoutRedirectUris(id) {
        return this.makeRequest({
            url: `/clients/${id}/postLogoutRedirectUris`
        });
    },

    getClientSecrets(id) {
        return this.makeRequest({
            url: `/clients/${id}/secrets`
        });
    },

    getClientCors(id) {
        return this.makeRequest({
            url: `/clients/${id}/corsOrigins`
        });
    },

    getResources() {
        return this.makeRequest({
            url: '/resources'
        });
    },

    getScopes(resourceId) {
        return this.makeRequest({
            url: `/resources/${resourceId}/scopes`
        });
    },

    addClientScope(clientId, scope) {
        const data = { scope };

        return this.makeRequest({
            method: 'POST',
            url: `/clients/${clientId}/scopes`,
            data
        });
    },

    removeClientScope(clientId, scopeId) {
        return this.makeRequest({
            method: 'DELETE',
            url: `/clients/${clientId}/scopes/${scopeId}`
        });
    },

    addClientRedirect(clientId, uri) {
        const data = { uri };

        return this.makeRequest({
            method: 'POST',
            url: `/clients/${clientId}/redirects`,
            data
        });
    },

    removeClientRedirect(clientId, redirectId) {
        return this.makeRequest({
            method: 'DELETE',
            url: `/clients/${clientId}/redirects/${redirectId}`
        });
    },

    addClientSecret(clientId, secret) {
        const data = {
            description: secret.description,
            expiration: secret.expiration
        };

        return this.makeRequest({
            method: 'POST',
            url: `/clients/${clientId}/secrets`,
            data
        });
    },

    removeClientSecret(clientId, secretId) {
        return this.makeRequest({
            method: 'DELETE',
            url: `/clients/${clientId}/secrets/${secretId}`
        });
    },

    addClientClaim(clientId, claim) {
        const data = { ...claim };

        return this.makeRequest({
            method: 'POST',
            url: `/clients/${clientId}/claims`,
            data
        });
    },

    removeClientClaim(clientId, claimId) {
        return this.makeRequest({
            method: 'DELETE',
            url: `/clients/${clientId}/claims/${claimId}`
        });
    },

    addClientPostLogoutRedirectUri(clientId, uri) {
        const data = { uri };

        return this.makeRequest({
            method: 'POST',
            url: `/clients/${clientId}/postLogoutRedirectUris`,
            data
        });
    },

    removeClientPostLogoutRedirectUri(clientId, postLogoutRedirectUriId) {
        return this.makeRequest({
            method: 'DELETE',
            url: `/clients/${clientId}/postLogoutRedirectUris/${postLogoutRedirectUriId}`
        });
    },

    addClientCors(clientId, origin) {
        const data = { origin };

        return this.makeRequest({
            method: 'POST',
            url: `/clients/${clientId}/corsOrigins`,
            data
        });
    },

    removeClientCors(clientId, corsId) {
        return this.makeRequest({
            method: 'DELETE',
            url: `/clients/${clientId}/corsOrigins/${corsId}`
        });
    },

    duplicateClient(clientId, newClientName) {
        const data = {
            NewClientId: newClientName
        };

        return this.makeRequest({
            method: 'POST',
            url: `/clients/${clientId}/clone`,
            data
        });
    },

    uploadClientLogo(clientId, blob) {
        return axios.post(`/backend/api/v1/clients/${clientId}/logo`, blob, {
            headers: {
                'X-CSRF': '1',
                'Content-Type': blob.type
            }
        });
    },

    removeClientLogo(clientId) {
        return this.makeRequest({
            method: 'DELETE',
            url: `/clients/${clientId}/logo`
        });
    }
};
